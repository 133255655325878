const breakpoints = {
  xsmall: 410,
  small: 640,
  medium: 768,
  large: 1024,
  xlarge: 1200,
  huge: 1600,
};

export default breakpoints;
