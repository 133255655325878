import React from "react";
import StatementPage from "../components/StatementPage";

const Statement = () => {
  return (
    <StatementPage />
  );
};

export default Statement;
